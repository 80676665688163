// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#F48FB1',//claro
        secondary: '#7c8293',//fuerte
        accent: '#BDBDBD',//medio
        //btn_color: '#91dd0b',
        btn_color: '#003a89',
        appbar_color: '#D81B60',
        //"layout-appbar__appbar": "#ffffff",
      },
      dark: {
        primary: '#50778D',
        secondary: '#0B1C3D',
        accent: '#204165',
      },
    },
  },
})
